/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */
const useMagentoCommerce = false; // setup uses magento commerce or community
const storeCode = ''; // fill it with any store code when the magento is setup with multiple stores. leave it empty to use default store.
const assetsVersion = '1.0.5';

const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://mitra10-linode.testingnow.me',
    stage: 'https://staging-huawei.mitra10.com',
    prod: 'https://www.mitra10.com',
};

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://bo.mitra10-linode.testingnow.me/graphql',
    dev: 'https://bo.mitra10-linode.testingnow.me/graphql',
    stage: 'https://web-staging.mitra10.com/graphql',
    prod: 'https://web.mitra10.com/graphql',
};

// BackofficeEndpoint
const backOfficeEndpoint = {
    local: 'https://bo.mitra10-linode.testingnow.me',
    dev: 'https://bo.mitra10-linode.testingnow.me',
    stage: 'https://web-staging.mitra10.com',
    prod: 'https://web.mitra10.com',
};

const pimEndPoint = {
    local: 'https://pimcore.testingnow.me',
    dev: 'https://pimcore.testingnow.me',
    stage: 'https://pim.mitra10.com',
    prod: 'https://pim.mitra10.com',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Mitra10';
const originName = 'pwa';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    whatsapp: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const languagesLabel = {
    id: 'Bahasa Indonesia',
    en: 'English',
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: true,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: 'GTM-TG2WB8T', // sample: GTM-N76V8KQ
        prod: 'GTM-TG2WB8T', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: false,
    siteKey: {
        local: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

// error management monitoring
const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://497c586cd4ee448495a72790fc11554c@o878311.ingest.sentry.io/5830028',
        dev: 'https://497c586cd4ee448495a72790fc11554c@o878311.ingest.sentry.io/5830028',
        stage: 'https://497c586cd4ee448495a72790fc11554c@o878311.ingest.sentry.io/5830028',
        prod: 'https://497c586cd4ee448495a72790fc11554c@o878311.ingest.sentry.io/5830028',
    },
};

const rollbar = {
    enabled: false,
    config: {
        accessToken: '76876f52664341b4a1981c4618723bda',
        captureUncaught: true,
        captureUnhandledRejections: true,
    },
};

/* Contact Us */
// identifiers for cmsBlocks in contact page
const cmsContactIdentifiers = 'weltpixel_contact_page';

/* Dashboard */
// identifiers for cmsBlocks in contact page
const cmsSocialMediaLinkIdentifiers = 'pwa_socmed_links';

/* Social media link */
// social media link in dashboard
const enableSocialMediaLink = true;

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
// const expiredToken = new Date(Date.now() + 1000 * 60 * 60);
const expiredToken = 9999999999;
const expiredBrowserAuth = new Date(new Date().getTime() + 60 * 60 * 168 * 1000);
const newsLetterExpiredTime = 1; // 1 day
const expiredDefault = 365;
const localResolverKey = 'resolver';

const features = {
    useCustomStyle: true,
    ssrCache: false,
    crm: {
        enabled: false,
        graphqlEndpoint: {
            local: 'http://swiftcrm.testingnow.me/graphql',
            dev: 'http://swiftcrm.testingnow.me/graphql',
            stage: 'http://swiftcrm.testingnow.me/graphql',
            prod: 'http://swiftcrm.testingnow.me/graphql',
        },
    },
    facebookMetaId: {
        enabled: false,
        app_id: '', // if enabled add fb app id here. e.g. 3080154482073095
    },
    // masuk module -> pindah jika module sudah siap
    productAvailableToCart: {
        SimpleProduct: true,
        ConfigurableProduct: true,
        VirtualProduct: true,
        GroupedProduct: false,
        BundleProduct: true,
        DownloadableProduct: false,
    },
    imageSize: {
        product: {
            width: 300,
            height: 300,
        },
        homeSlider: {
            mobile: {
                width: 520,
                height: 480,
            },
            desktop: {
                width: 1210,
                height: 340,
            },
        },
        category: {
            width: 960,
            height: 577,
        },
    },
    vesMenu: {
        enabled: true,
        alias: 'top-menu',
    },
    customInstallApp: {
        enabled: true,
    },
    pushNotification: {
        enabled: true,
        config: {
            // mitra10
            // apiKey: 'AIzaSyDnSJNJbVNrm43D934XrvgCogrwmtAAxBg',
            // authDomain: 'mitra10-983e1.firebaseapp.com',
            // projectId: 'mitra10-983e1',
            // storageBucket: 'mitra10-983e1.appspot.com',
            // messagingSenderId: '310768856075',
            // appId: '1:310768856075:web:d1dea9d803dd38f249eb0e',
            // measurementId: 'G-H7C5MVG0BY',
            // pairKey: 'BP6aKQ1spSIsrpUIq7pHTybwRffBaQektq326TvIhA_DgGBTK_00CAPJn4bgiH1FSopg6HTELJ7c6uf9aA45mlw',

            // Mitra10-Firebase
            apiKey: 'AIzaSyCIYGozAdvuLBwmR9zF_MR1FnEa9vxV8Uo',
            authDomain: 'mitra10-firebase.firebaseapp.com',
            projectId: 'mitra10-firebase',
            storageBucket: 'mitra10-firebase.appspot.com',
            messagingSenderId: '739506905970',
            appId: '1:739506905970:web:4a651db90eabc88f3bb3eb',
            measurementId: 'G-M12R14MNPG',
            pairKey: 'BC-FWqX0KsAJDH6o561GS_Klp0o4aL-OYCeBRhTGqypdKzEPgaT48e6TCCMp5LEKgEvTwDWNISWF3Rmbr3fjEHc',

            databaseURL: '', // 'https://swiftpwa-firebase.firebaseio.com',
        },
    },
    thumbor: {
        enable: true,
        useHttpsOrHttp: false,
        url: 'https://swift-thumbor.sirclocdn.com/unsafe/widthxheight/filters:format(webp)/',
        thumborUrl: {
            local: 'https://cdn.gcp-staging.testingnow.me',
            dev: 'https://cdn.gcp-staging.testingnow.me',
            stage: 'https://cdn.gcp-staging.testingnow.me',
            prod: 'https://swift-thumbor.sirclocdn.com',
        },
    },
    globalPromo: {
        enable: true,
        key_cookies: 'global_promo_enable',
    },
};

const modules = {
    authentication: {
        enabled: true,
        path: '/authentication',
    },
    about: {
        enabled: true,
        path: '/about-us',
    },
    blog: {
        enabled: true,
        path: '/blog',
        link: {
            detail: {
                href: '/blog/[id]',
                as: '/blog/',
            },
            category: {
                href: '/blog/category/[id]',
                as: '/blog/category/',
            },
            default: {
                href: '/blog',
            },
        },
        featuredImage: true,
    },
    brands: {
        enabled: true,
        path: '/brands',
    },
    catalog: {
        enabled: true,
        productListing: {
            pageSize: 24,
            drawerFilterOnDesktop: {
                enabled: true, // used if need to desktop view on large screen
            },
            label: {
                enabled: true,
                new: {
                    enabled: true,
                },
                sale: {
                    enabled: false,
                },
            },
            configurableOptions: {
                enabled: false,
            },
            rating: {
                enabled: true,
            },
        },
        pdp: {
            popupDetailImage: {
                enabled: true,
            },
        },
    },
    confirmpayment: {
        enabled: true,
        path: '/confirmpayment',
    },
    checkout: {
        enabled: true,
        checkoutOnly: false,
        path: '/checkout',
        ipayUrl: {
            local: 'ipayredirect/ipayredirect/?orderId=',
            dev: 'ipayredirect/ipayredirect/?orderId=',
            stage: 'ipayredirect/ipayredirect/?orderId=',
            prod: 'ipayredirect/ipayredirect/?orderId=',
        },
        snapUrl: {
            dev: 'https://app.sandbox.midtrans.com/snap/snap.js',
            prod: 'https://app.midtrans.com/snap/snap.js',
        },
        atomeUrl: 'atome/pwa/redirect/cart_id/',
        pickupStore: {
            enabled: true,
        },
        extraFee: {
            enabled: false,
        },
        cashback: {
            enabled: true,
        },
        xendit: {
            paymentPrefixCodeOnSuccess: ['alfamart', 'bcava', 'briva', 'bniva', 'mandiriva', 'permatava', 'indomaret'],
            paymentPrefixCode: ['cc', 'cc_subscription', 'linkaja', 'qr_codes', 'dd_bri', 'kredivo', 'dana', 'ovo', 'akulaku'],
        },
    },
    cart: {
        enabled: true,
        path: '/checkout/cart',
    },
    customer: {
        enabled: true,
        path: '/customer',
        plugin: {
            address: {
                splitCity: true,
            },
            newsletter: {
                enabled: true,
            },
        },
    },
    contact: {
        enabled: true,
        path: '/contact',
    },
    forgotpassword: {
        enabled: true,
        path: '/customer/account/forgotpassword',
    },
    rewardpoint: {
        enabled: true,
        path: '/aw_rewardpoints/info',
    },
    rma: {
        enabled: false,
        path: '/rma/customer',
    },
    storecredit: {
        enabled: true,
        path: '/customer/account/storecredit',
        useCommerceModule: false,
    },
    extendmembership: {
        enabled: true,
        path: '/customer/account/extendmembership',
    },
    storeLocator: {
        enabled: true,
        path: '/storelocator',
    },
    giftcard: {
        enabled: false,
        path: '/awgiftcard/card',
        useCommerceModule: false,
    },
    login: {
        enabled: true,
        path: '/customer/account/login',
    },
    notification: {
        enabled: true,
        path: 'inboxnotification/notification',
    },
    register: {
        enabled: true,
        path: '/customer/account/create',
    },
    trackingorder: {
        enabled: true,
        path: '/sales/order/track',
        fieldDetail: {
            shipperid: ['name', 'description', 'updateDate'],
            gosend: [
                'bookingType',
                'buyerAddressName',
                'buyerAddressDetail',
                'driverId',
                'driverName',
                'insuranceDetails',
                'liveTrackingUrl',
                'receiverName',
                'sellerAddressDetail',
                'sellerAddressName',
                'status',
                'cancelDescription',
                'orderArrivalTime',
                'orderClosedTime',
                'orderCreatedTime',
            ],
        },
    },
    thanks: {
        enabled: true,
        path: '/checkout/onepage/success',
    },
    home: {
        enabled: true,
        flashSaleProduct: {
            enable: true,
            url_key: 'homepage-flash-sale',
        },
        featuresProduct: {
            enable: true,
            url_key: 'best-seller',
        },
        bbm: {
            enable: true,
            url_key: 'borong-barang-mitra10',
        },
        categoryList: {
            enable: true,
            url_key: 'homepage-featured-categories',
            imageSize: {
                mobile: {
                    width: 960,
                    height: 577,
                },
                desktop: {
                    width: 1254,
                    height: 350,
                },
            },
            thumbnailSize: {
                mobile: {
                    width: 960,
                    height: 577,
                },
                desktop: {
                    width: 530,
                    height: 480,
                },
            },
        },
        bannerSlider: {
            enable: true,
            title: 'Mitra10 Home Slider',
        },
    },
    promo: {
        enabled: true,
    },
    order: {
        enabled: true,
        path: '/sales/order',
    },
    wishlist: {
        enabled: true,
        path: '/wishlist',
    },
    point: {
        enabled: true,
        path: '/point',
    },
    igpost: {
        enabled: true,
        path: 'igpost/[token]',
    },
    maintenance: {
        enabled: true,
        path: '/maintenance',
    },
    error: {
        enabled: true,
    },
};

const nossrCache = [
    '/aw_rewardpoints/info',
    '/sales/order/history',
    '/customer/account/profile',
    '/customer/account/address',
    '/awgiftcard/card',
    '/customer/account/storecredit',
    '/inboxnotification/notification',
    '/customer/setting',
    '/rma/customer',
    '/confirmpayment',
    '/checkout',
    '/checkout/cart',
    '/graphql',
    '/authentication',
    '/checkout/onepage/success',
];

const debuging = {
    originalError: false,
};

const general = {
    defaultCurrencyCode: 'IDR',
    defaultDecimal: 0,
};

module.exports = {
    assetsVersion,
    general,
    sentry,
    storeCode,
    debuging,
    GTM,
    HOST,
    graphqlEndpoint,
    backOfficeEndpoint,
    shareIcon,
    passwordStrength,
    languagesLabel,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredBrowserAuth,
    newsLetterExpiredTime,
    expiredDefault,
    loaderImage,
    cmsContactIdentifiers,
    cmsSocialMediaLinkIdentifiers,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    enableSocialMediaLink,
    features,
    nossrCache,
    recaptcha,
    modules,
    installMessage,
    appName,
    localResolverKey,
    originName,
    useMagentoCommerce,
    rollbar,
    pimEndPoint,
};
